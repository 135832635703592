import React from 'react';
import { motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';

const AnimatedBackground = () => {
  const canvasRef = React.useRef(null);
  const containerRef = React.useRef(null);
  const animationRef = React.useRef(null);
  const pointsRef = React.useRef(null);

  const initializePoints = (width, height) => {
    return Array.from({ length: 50 }, () => ({
      x: Math.random() * width,
      y: Math.random() * height,
      vx: (Math.random() - 0.5) * 0.3,
      vy: (Math.random() - 0.5) * 0.3,
      radius: Math.random() * 1.5 + 1,
      color: Math.random() > 0.6 
        ? '#60a5fa'
        : Math.random() > 0.3 
          ? '#3b82f6'
          : '#2563eb'
    }));
  };

  const animate = React.useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d', { alpha: true });
    
    if (!canvas.width || !canvas.height || !pointsRef.current) return;
    
    // Clear with slight fade effect
    ctx.fillStyle = 'rgba(10, 16, 23, 0.2)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    pointsRef.current.forEach(point => {
      point.x += point.vx;
      point.y += point.vy;
      
      if (point.x < 0) point.x = canvas.width;
      if (point.x > canvas.width) point.x = 0;
      if (point.y < 0) point.y = canvas.height;
      if (point.y > canvas.height) point.y = 0;
      
      // Enhanced glow effect
      ctx.beginPath();
      const gradient = ctx.createRadialGradient(
        point.x, point.y, 0,
        point.x, point.y, point.radius * 30
      );
      gradient.addColorStop(0, `${point.color}80`);
      gradient.addColorStop(0.4, `${point.color}40`);
      gradient.addColorStop(1, 'transparent');
      
      ctx.fillStyle = gradient;
      ctx.arc(point.x, point.y, point.radius * 30, 0, Math.PI * 2);
      ctx.fill();
      
      // Bright center point
      ctx.beginPath();
      ctx.fillStyle = point.color;
      ctx.arc(point.x, point.y, point.radius * 0.8, 0, Math.PI * 2);
      ctx.fill();
    });
    
    // Enhanced connections
    pointsRef.current.forEach((point, i) => {
      pointsRef.current.slice(i + 1).forEach(otherPoint => {
        const dx = point.x - otherPoint.x;
        const dy = point.y - otherPoint.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        
        if (distance < 200) {
          ctx.beginPath();
          ctx.strokeStyle = `rgba(59, 130, 246, ${(200 - distance) / 200 * 0.25})`;
          ctx.lineWidth = 1;
          ctx.moveTo(point.x, point.y);
          ctx.lineTo(otherPoint.x, otherPoint.y);
          ctx.stroke();
        }
      });
    });
    
    animationRef.current = requestAnimationFrame(animate);
  }, []);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    const ctx = canvas.getContext('2d', { alpha: true });
    
    const setCanvasSize = () => {
      const dpr = window.devicePixelRatio || 1;
      const rect = container.getBoundingClientRect();
      canvas.width = rect.width * dpr;
      canvas.height = rect.height * dpr;
      ctx.scale(dpr, dpr);
      canvas.style.width = `${rect.width}px`;
      canvas.style.height = `${rect.height}px`;

      // Always initialize points when canvas size is set
      pointsRef.current = initializePoints(rect.width, rect.height);
    };
    
    const resizeObserver = new ResizeObserver(() => {
      // Cancel any existing animation frame before resize
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      setCanvasSize();
      animationRef.current = requestAnimationFrame(animate);
    });

    // Initial setup
    setCanvasSize();
    animate();
    
    resizeObserver.observe(container);

    // Cleanup
    return () => {
      resizeObserver.disconnect();
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [animate]);

  return (
    <div ref={containerRef} className="absolute inset-0 overflow-hidden">
      <div className="absolute inset-0 bg-[#0A1017]" />
      <canvas
        ref={canvasRef}
        className="absolute inset-0 w-full h-full"
        style={{ mixBlendMode: 'screen', opacity: 0.8 }}
      />
    </div>
  );
};

const Hero = () => {
  return (
    <section className="relative min-h-screen flex items-center overflow-hidden">
      {/* Base dark background */}
      <div className="absolute inset-0 bg-[#0A1017]" />
      
      {/* Animated background */}
      <AnimatedBackground />
      
      {/* Subtle blue gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900/10 via-transparent to-blue-900/10" />
      
      {/* Content */}
      <div className="container mx-auto px-6 relative z-10">
        <div className="max-w-4xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="text-left"
          >
            <h1 className="text-5xl md:text-6xl lg:text-7xl font-light tracking-tight mb-8 mt-32 md:mt-20">
              <span className="text-white">Transform Your</span>
              <br />
              <span className="text-blue-300">
                Enterprise AI Strategy
              </span>
            </h1>
            
            <p className="text-xl md:text-2xl text-gray-200 font-light leading-relaxed mb-12 max-w-2xl">
              Partner with industry leaders to develop and implement enterprise-grade AI solutions that drive measurable business outcomes.
            </p>

            <div className="flex flex-col sm:flex-row gap-6">
              <motion.a 
                href="#calendly" 
                className="group inline-flex items-center justify-center px-8 py-4 text-lg font-light text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors duration-300"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Schedule Executive Briefing
                <ChevronRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </motion.a>
              
              <motion.a 
                href="#case-studies" 
                className="group inline-flex items-center justify-center px-8 py-4 text-lg font-light text-white bg-white/5 hover:bg-white/10 rounded-md border border-white/10 transition-colors duration-300"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                View Success Stories
                <ChevronRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </motion.a>
            </div>
            
            <div className="mt-16 pt-16 pb-24 md:pb-16 border-t border-white/10"> {/* Updated padding bottom */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              {[
                { number: "95%", label: "Client Retention Rate" },
                { number: "$150M+", label: "Value Generated" },
                { number: "20+", label: "AI Implementations" }
              ].map((stat, index) => (
                <div key={index} className="text-center">
                  <div className="text-4xl font-light text-white mb-3">
                    {stat.number}
                  </div>
                  <div className="text-sm text-blue-200 uppercase tracking-wider">
                    {stat.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Hero;