import React from 'react';

const PrivacyPolicy = () => (
  <div className="bg-[#0A1017] min-h-screen mt-24">
    <div className="container mx-auto px-4 md:px-16 py-12">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-2xl md:text-3xl text-white mb-4">Privacy Policy</h1>
        <p className="text-sm text-gray-400 mb-8">Last updated: November 22, 2024</p>
        
        <div className="space-y-8">
          <section>
            <h2 className="text-lg text-white mb-3">1. Information We Collect</h2>
            <p className="text-gray-300 text-sm mb-2">We collect information that you provide directly to us, including:</p>
            <ul className="text-sm text-gray-300 list-disc pl-4 space-y-1">
              <li>Contact information (name, email address, phone number)</li>
              <li>Company information (organization name, title, industry)</li>
              <li>Meeting preferences and scheduling information</li>
              <li>Information shared during consultations and meetings</li>
            </ul>
          </section>

          <section>
            <h2 className="text-lg text-white mb-3">2. How We Use Your Information</h2>
            <ul className="text-sm text-gray-300 list-disc pl-4 space-y-1">
              <li>Provide consulting and advisory services</li>
              <li>Schedule and manage meetings</li>
              <li>Send relevant communications and updates</li>
              <li>Improve our services and develop new offerings</li>
            </ul>
          </section>

          <section>
            <h2 className="text-lg text-white mb-3">3. Information Sharing</h2>
            <p className="text-gray-300 text-sm mb-2">We do not sell, rent, or trade your personal information. We may share your information with:</p>
            <ul className="text-sm text-gray-300 list-disc pl-4 space-y-1">
              <li>Service providers who assist in our operations</li>
              <li>Professional advisors and consultants</li>
              <li>As required by law or to protect our rights</li>
            </ul>
          </section>

          <section>
            <h2 className="text-lg text-white mb-3">4. Data Security</h2>
            <p className="text-sm text-gray-300">
              We implement appropriate technical and organizational measures to protect your personal information. However, no method of transmission over the Internet is 100% secure.
            </p>
          </section>

          <section>
            <h2 className="text-lg text-white mb-3">5. Your Rights</h2>
            <ul className="text-sm text-gray-300 list-disc pl-4 space-y-1">
              <li>Access your personal information</li>
              <li>Correct inaccurate information</li>
              <li>Request deletion of your information</li>
              <li>Opt-out of marketing communications</li>
            </ul>
          </section>

          <section className="pt-4">
            <h2 className="text-lg text-white mb-3">6. Contact Us</h2>
            <div className="text-sm text-gray-300 space-y-1">
              <p>For privacy-related inquiries:</p>
              <p>Email: privacy@fulkersonadvisors.com</p>
              <p>Address: PO Box 410127, Cambridge, MA 02141</p>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
);

export default PrivacyPolicy;