import React from 'react';
import { motion } from 'framer-motion';
import { Users, Brain, Network, Globe, Shield, Database } from 'lucide-react';

const ExpertCard = ({ icon: Icon, title, expertise, count }) => (
  <motion.div 
    className="relative group h-full"
    whileHover={{ y: -2 }}
  >
    <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-500/20 to-blue-600/20 rounded-lg blur opacity-0 group-hover:opacity-75 transition duration-500" />
    <div className="relative h-full bg-gradient-to-br from-gray-900 to-blue-950 p-8 rounded-lg border border-blue-200/10 hover:border-blue-400/30 transition-colors duration-300">
      <div className="flex items-center gap-4 mb-6">
        <div className="p-3 rounded-lg bg-blue-500/10">
          <Icon className="w-6 h-6 text-blue-300" />
        </div>
        <h3 className="text-xl font-light text-white">
          {title}
        </h3>
      </div>

      <div className="space-y-4">
        <p className="text-blue-100/70 font-light leading-relaxed mb-6">
          {expertise}
        </p>
      </div>
    </div>
  </motion.div>
);

const NetworkSection = () => {
  const experts = [
    {
      icon: Brain,
      title: "AI/ML Scientists",
      expertise: "PhD-level researchers and practitioners specializing in machine learning, deep learning, and neural networks.",
    },
    {
      icon: Database,
      title: "Data Architects",
      expertise: "Enterprise data architecture experts with experience designing and implementing large-scale AI systems.",
    },
    {
      icon: Shield,
      title: "Security Specialists",
      expertise: "Information security experts focusing on AI system security, data privacy, and compliance frameworks.",
    },
    {
      icon: Users,
      title: "Domain Experts",
      expertise: "Industry veterans bringing deep expertise in financial services, healthcare, retail, and technology sectors.",
    },
    {
      icon: Network,
      title: "Integration Engineers",
      expertise: "Technical specialists in enterprise system integration, cloud infrastructure, and distributed systems.",
    },
    {
      icon: Globe,
      title: "Implementation Leaders",
      expertise: "Experienced project leaders who have overseen successful enterprise AI transformations globally.",
    }
  ];

  return (
    <section className="py-24 bg-[#0A1017]">
      <div className="container mx-auto px-6">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="max-w-3xl mx-auto text-center mb-16"
        >
          <h2 className="text-4xl font-light text-white mb-6">
            Expert Network
          </h2>
          <p className="text-xl text-blue-100/70 font-light">
            A curated network of 20+ subject matter experts we've successfully collaborated with over the past 18 months
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {experts.map((expert, index) => (
            <motion.div
              key={expert.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <ExpertCard {...expert} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NetworkSection;