import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, ChevronRight } from 'lucide-react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const navItems = [
        { name: 'Services', href: '/#services' },
        { name: 'Expert Network', href: '/#network' },
        { name: 'Testimonials', href: '/#testimonials' },
        { name: 'Success Stories', href: '/#case-studies' },
        { name: 'Approach', href: '/#approach' },
        { name: 'Leadership', href: '/#team' },
        { name: 'Advisory Board', href: '/#advisory' },
      ];

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuOpen]);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = element.offsetTop - 80;
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      });
    }
  };

  const handleNavClick = async (e, href) => {
    e.preventDefault();
    setIsMenuOpen(false);

    if (location.pathname !== '/' && href.startsWith('/#')) {
      const sectionId = href.substring(2);
      await navigate('/');
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          scrollToSection(sectionId);
        });
      });
      return;
    }

    if (location.pathname === '/') {
      if (href === '/') {
        window.scrollTo({ top: 0 });
      } else if (href.startsWith('/#')) {
        scrollToSection(href.substring(2));
      }
    } else {
      if (href === '/') {
        navigate('/');
        window.scrollTo({ top: 0 });
      } else {
        navigate(href);
      }
    }
  };

  return (
    <>
      <header 
        className={`fixed top-0 left-0 right-0 z-40 transition-colors duration-300 ${
          isMenuOpen ? 'bg-[#0A1017]' : scrolled ? 'bg-[#0A1017]/95 backdrop-blur-sm' : 'bg-transparent'
        }`}
      >
        <div className="container mx-auto px-4 md:px-8">
          <div className="flex justify-between items-center h-20">
            <a 
              href="/"
              className="relative z-50 mr-8 flex items-center"
              onClick={(e) => handleNavClick(e, '/')}
            >
              <img 
                src="/logo.png" 
                alt="Fulkerson Advisors" 
                className="h-6 md:h-8 w-auto object-contain min-w-[120px]" 
              />
            </a>

            <nav className="hidden xl:block">
              <ul className="flex items-center gap-6">
                {navItems.map((item) => (
                  <li key={item.name}>
                    <a 
                      href={item.href}
                      className="text-gray-300 hover:text-white transition-colors duration-300 text-sm whitespace-nowrap px-2"
                      onClick={(e) => handleNavClick(e, item.href)}
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
                <li className="ml-4">
                  <a 
                    href="/#calendly"
                    className="inline-flex items-center justify-center h-9 px-5 bg-blue-600 hover:bg-blue-700 text-white text-sm font-light rounded-md transition-colors duration-300 whitespace-nowrap"
                    onClick={(e) => handleNavClick(e, '/#calendly')}
                  >
                    Schedule Executive Briefing
                  </a>
                </li>
              </ul>
            </nav>

            <button 
              onClick={() => setIsMenuOpen(!isMenuOpen)} 
              className="xl:hidden relative z-50 text-white hover:text-blue-300 transition-colors p-2"
              aria-label="Toggle mobile menu"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </header>

      {/* Mobile menu overlay - moved outside header */}
      <div 
        className={`fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity duration-300 lg:hidden z-40 ${
          isMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setIsMenuOpen(false)}
      />

      {/* Mobile menu sidebar - moved outside header */}
      <div 
        className={`fixed inset-y-0 right-0 w-[280px] bg-[#0A1017] transform transition-transform duration-300 ease-in-out lg:hidden z-50 ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="h-full flex flex-col mt-20">
          <div className="flex-1 overflow-y-auto py-6 px-6">
            <nav>
              <ul className="space-y-1">
                {navItems.map((item) => (
                  <li key={item.name}>
                    <a 
                      href={item.href}
                      className="flex items-center justify-between py-3 px-4 text-gray-300 hover:text-white hover:bg-white/5 rounded-lg transition-colors duration-200"
                      onClick={(e) => handleNavClick(e, item.href)}
                    >
                      <span className="text-base font-medium">{item.name}</span>
                      <ChevronRight size={16} className="text-gray-400" />
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          
          <div className="p-6 border-t border-white/10">
            <a 
              href="/#calendly"
              className="flex items-center justify-center w-full py-3 px-4 bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium rounded-lg transition-colors duration-200"
              onClick={(e) => handleNavClick(e, '/#calendly')}
            >
              Schedule Executive Briefing
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;