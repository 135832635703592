import React, { useEffect, useState } from 'react';
import { Calendar, Clock, Users, Building2 } from 'lucide-react';

const BenefitCard = ({ icon: Icon, title, description }) => (
  <div className="flex items-start gap-4">
    <div className="p-2 bg-blue-500/10 rounded-lg">
      <Icon className="w-6 h-6 text-blue-400" />
    </div>
    <div>
      <h3 className="text-lg text-white mb-1">{title}</h3>
      <p className="text-gray-400 text-sm">{description}</p>
    </div>
  </div>
);

const Calendly = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    script.onload = () => setIsLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      window.Calendly.initInlineWidget({
        url: 'https://calendly.com/fulkerson-advisors/exploratory-strategy-call?hide_gdpr_banner=1&background_color=060913&text_color=ffffff&primary_color=3b82f6',
        parentElement: document.getElementById('calendly-inline-widget'),
        prefill: {},
        utm: {}
      });
    }
  }, [isLoaded]);

  return (
    <section id="calendly" className="py-24 bg-[#060913]"> {/* Darker background for contrast */}
      <div className="container mx-auto px-4 md:px-16">
        <div className="flex flex-col md:flex-row gap-8 md:gap-16">
          {/* Left Column - Content */}
          <div className="w-full md:w-1/2 md:h-[800px] flex flex-col">
            <div className="md:h-full flex flex-col justify-center md:pt-12 md:overflow-y-auto"> {/* Reduced top padding */}
              <div>
                <h2 className="text-3xl md:text-4xl text-white mb-3 md:mb-4">
                  Schedule an Executive Briefing
                </h2>
                <p className="text-lg md:text-xl text-gray-400 mb-8 md:mb-12 leading-relaxed">
                  Explore how our enterprise AI expertise can accelerate your organization's AI transformation journey.
                </p>

                <div className="space-y-6 md:space-y-8 mb-8 md:mb-12">
                  <BenefitCard 
                    icon={Clock}
                    title="45-Minute Strategic Discussion"
                    description="Focused conversation on your organization's specific AI challenges and opportunities"
                  />
                  <BenefitCard 
                    icon={Users}
                    title="Senior Leadership Focus"
                    description="Tailored for C-suite executives and senior decision-makers"
                  />
                  <BenefitCard 
                    icon={Building2}
                    title="Enterprise Perspective"
                    description="Insights from successful Fortune 500 AI implementations"
                  />
                  <BenefitCard 
                    icon={Calendar}
                    title="Flexible Scheduling"
                    description="Select a time that works best for your calendar"
                  />
                </div>

                <div className="text-sm text-gray-400">
                  For immediate assistance, contact us directly at<br/>
                  <a href="mailto:info@fulkersonadvisors.com" className="text-blue-400 hover:text-blue-300 transition-colors">
                    info@fulkersonadvisors.com
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Calendly Widget */}
          <div className="w-full md:w-1/2">
            <div className="bg-[#0A1017] rounded-lg overflow-hidden"> {/* Slightly lighter than section background */}
              {isLoaded ? (
                <div 
                  id="calendly-inline-widget"
                  className="h-[600px] md:h-[1000px]" // Reduced height
                />
              ) : (
                <div className="h-[600px] md:h-[800px] flex items-center justify-center">
                  <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-400"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calendly;