import React from 'react';
import { motion } from 'framer-motion';

const AdvisoryProfile = ({ name, title, image, bio }) => (
  <div className="flex flex-col md:flex-row gap-12 md:gap-24 mb-40 last:mb-0">
    {/* Left Column - Name, Image */}
    <div className="w-full md:w-[40%]">
      {/* Name and Title First */}
      <div className="mb-8">
        <h2 className="text-3xl md:text-4xl text-white font-light tracking-tight">{name}</h2>
        <h3 className="text-lg md:text-xl text-blue-400 mt-2 font-light">{title}</h3>
      </div>

      {/* Image Container */}
      <div className="relative bg-[#0A1017] h-[500px]">
        <div className="absolute inset-0 bg-[#0A1017]" />
        <img 
          src={image} 
          alt={name}
          className="relative z-10 w-full h-full object-contain"
        />
      </div>
    </div>

    {/* Right Column - Bio */}
    <div className="w-full md:w-[60%]">
      <div className="h-full flex flex-col justify-center">
        {/* Bio Section */}
        <div className="space-y-6">
          {bio.map((paragraph, index) => (
            <p key={index} className="text-gray-300 text-lg font-light leading-relaxed">
              {paragraph}
            </p>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const AdvisoryBoard = () => {
  const advisors = [
    {
      name: "Carl Nehme, Ph.D.",
      title: "Technology, Product, and Sales Advisor",
      image: "/carl.jpeg",
      bio: [
        "Carl Nehme brings over 15 years of leadership experience at the intersection of healthcare, technology, and consumer innovation. He has successfully built and scaled cross-functional teams across product development, data science, operations, B2B/B2C sales and marketing, people, and finance.",
        "Most recently, as co-founder of Goodpath, Carl led the development of the first virtual clinic model for chronic care, pioneering innovation that delivered industry-leading health outcomes and significant cost savings.",
        "Prior to Goodpath, Carl spent six years at McKinsey & Company, working in the Dubai and Santiago offices, where he focused on driving operational excellence in healthcare and logistics. In 2014, Carl relocated to Boston to co-found an e-commerce augmented reality (AR) startup, which was acquired by Wayfair in 2016. Following the acquisition, Carl joined Wayfair's product leadership team, where he played a key role in introducing machine learning-driven personalization to enhance the shopping experience.",
        "Carl holds an S.M. and Ph.D. in Aeronautical and Astronautical Engineering from MIT."
      ]
    }
  ];

  return (
    <section id="advisory-board" className="py-24 md:py-32 bg-gradient-to-br from-gray-900 via-black to-blue-950">
      <div className="container mx-auto px-4 md:px-16">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="max-w-3xl mx-auto text-center mb-16"
        >
          <h2 className="text-4xl font-light text-white mb-6">
            Advisory Board
          </h2>
          <p className="text-xl text-blue-100/70 font-light">
            Industry experts guiding our strategic direction
          </p>
        </motion.div>

        {advisors.map((advisor, index) => (
          <AdvisoryProfile 
            key={index}
            {...advisor}
          />
        ))}
      </div>
    </section>
  );
};

export default AdvisoryBoard;