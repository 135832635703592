import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Users, Code, BarChart, BookOpen, Globe } from 'lucide-react';

const ServiceCard = ({ icon: Icon, title, description, features }) => (
  <motion.div 
    className="relative group h-full"
    whileHover={{ y: -2 }}
  >
    <div className="h-full bg-gradient-to-br from-gray-900 to-blue-950 rounded-lg border border-blue-200/10 overflow-hidden">
      <div className="p-8">
        <div className="flex items-center gap-4 mb-6">
          <div className="p-3 rounded-lg bg-blue-500/10">
            <Icon className="w-6 h-6 text-blue-300" />
          </div>
          <h3 className="text-xl font-light text-white">
            {title}
          </h3>
        </div>
        
        <p className="text-blue-100/70 font-light mb-6 leading-relaxed">
          {description}
        </p>
        
        <ul className="space-y-3">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center gap-3 text-sm text-blue-200/60">
              <div className="w-1 h-1 rounded-full bg-blue-400/40" />
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </motion.div>
);

const Services = () => {
  const services = [
    {
      icon: Shield,
      title: "Enterprise AI Strategy",
      description: "Develop a comprehensive AI roadmap aligned with your organization's strategic objectives.",
      features: [
        "Executive alignment workshops",
        "Technology stack assessment",
        "Risk and compliance framework",
        "ROI modeling and validation"
      ]
    },
    {
      icon: Users,
      title: "Change Management",
      description: "Enable successful AI adoption across your organization with proven methodologies.",
      features: [
        "Stakeholder engagement planning",
        "Training and enablement programs",
        "Communication strategy",
        "Success metrics and tracking"
      ]
    },
    {
      icon: Code,
      title: "Technical Implementation",
      description: "Execute your AI strategy with enterprise-grade development and deployment.",
      features: [
        "Custom AI solution development",
        "System integration services",
        "Security and compliance",
        "Performance optimization"
      ]
    },
    {
      icon: BarChart,
      title: "Value Realization",
      description: "Measure and maximize the return on your AI investments.",
      features: [
        "KPI development and tracking",
        "Performance analytics",
        "Optimization recommendations",
        "ROI reporting"
      ]
    },
    {
      icon: BookOpen,
      title: "AI Education",
      description: "Elevate your organization's AI literacy and capabilities.",
      features: [
        "Executive AI workshops",
        "Technical team training",
        "Use case development",
        "Best practices guidance"
      ]
    },
    {
      icon: Globe,
      title: "Enterprise Scaling",
      description: "Scale AI solutions across your global enterprise efficiently.",
      features: [
        "Global deployment strategy",
        "Multi-region implementation",
        "Localization support",
        "Cross-functional alignment"
      ]
    }
  ];

  return (
    <section className="py-24 bg-gradient-to-br from-gray-900 via-black to-blue-950">
      <div className="container mx-auto px-6">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="max-w-3xl mx-auto text-center mb-16"
        >
          <h2 className="text-4xl font-light text-white mb-6">
            Enterprise AI Services
          </h2>
          <p className="text-xl text-blue-100/70 font-light">
            Comprehensive solutions for organizations committed to leading with AI
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <ServiceCard {...service} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;