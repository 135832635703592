import React from 'react';

const TermsOfService = () => (
  <div className="bg-[#0A1017] min-h-screen mt-24">
    <div className="container mx-auto px-4 md:px-16 py-12">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-2xl md:text-3xl text-white mb-4">Terms of Service</h1>
        <p className="text-sm text-gray-400 mb-8">Last updated: November 22, 2024</p>
        
        <div className="space-y-8">
          <section>
            <h2 className="text-lg text-white mb-3">1. Agreement to Terms</h2>
            <p className="text-sm text-gray-300">
              By accessing or using Fulkerson Advisors' services, you agree to be bound by these Terms of Service. If you disagree with any part of the terms, you may not access our services.
            </p>
          </section>

          <section>
            <h2 className="text-lg text-white mb-3">2. Services</h2>
            <p className="text-gray-300 text-sm mb-2">Our services include:</p>
            <ul className="text-sm text-gray-300 list-disc pl-4 space-y-1">
              <li>Strategic advisory services</li>
              <li>Implementation guidance</li>
              <li>Executive briefings and consultations</li>
              <li>Project management and oversight</li>
            </ul>
          </section>

          <section>
            <h2 className="text-lg text-white mb-3">3. Professional Services Agreement</h2>
            <p className="text-sm text-gray-300">
              All consulting engagements require a signed Professional Services Agreement. The terms of individual engagements are governed by separate statements of work.
            </p>
          </section>

          <section>
            <h2 className="text-lg text-white mb-3">4. Intellectual Property</h2>
            <p className="text-sm text-gray-300">
              All materials, methodologies, and deliverables created by Fulkerson Advisors remain our intellectual property unless explicitly specified otherwise in a written agreement.
            </p>
          </section>

          <section>
            <h2 className="text-lg text-white mb-3">5. Confidentiality</h2>
            <p className="text-sm text-gray-300">
              We maintain strict confidentiality of all client information and require signed non-disclosure agreements before beginning engagements.
            </p>
          </section>

          <section>
            <h2 className="text-lg text-white mb-3">6. Limitation of Liability</h2>
            <p className="text-sm text-gray-300">
              Fulkerson Advisors shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our services.
            </p>
          </section>

          <section>
            <h2 className="text-lg text-white mb-3">7. Changes to Terms</h2>
            <p className="text-sm text-gray-300">
              We reserve the right to modify these terms at any time. We will notify clients of any material changes.
            </p>
          </section>

          <section className="pt-4">
            <h2 className="text-lg text-white mb-3">8. Contact Information</h2>
            <div className="text-sm text-gray-300 space-y-1">
              <p>For questions about these terms:</p>
              <p>Email: legal@fulkersonadvisors.com</p>
              <p>Address: PO Box 410127, Cambridge, MA 02141</p>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
);

export default TermsOfService;