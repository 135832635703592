import React, { useEffect } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import SuccessStories from './components/SuccessStories';
import Testimonials from './components/Testimonials';
import Approach from './components/Approach';
import About from './components/About';
import AdvisoryBoard from './components/AdvisoryBoard';
import Calendly from './components/Calendly';
import Footer from './components/Footer';
import NetworkSection from './components/NetworkSection';

import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const MainContent = () => (
  <main>
    <section id="hero">
      <Hero />
    </section>
    <section id="services">
      <Services />
    </section>
    <section id="network">
      <NetworkSection />
    </section>
    <section id="testimonials">
      <Testimonials />
    </section>
    <section id="case-studies">
      <SuccessStories />
    </section>
    <section id="approach">
      <Approach />
    </section>
    <section id="team">
      <About />
    </section>
    <section id="advisory">
      <AdvisoryBoard />
    </section>
    <section id="calendly">
      <Calendly />
    </section>
  </main>
);

const App = () => {
  return (
    <BrowserRouter>
      <div className="font-sans text-white bg-black">
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;